<template>
  <div id="forget_password">
    <div class="shadow"></div>
    <div class="content" v-if="index < 2">
      <div class="steps">
        <div class="state" :style="index === 1 ? 'opacity: 0.5;' : ''">
          <span></span>
          <span>1</span>
          <p>填写手机号码</p>
        </div>
        <div :class="index === 1 ? 'state' : ''">
          <span></span>
          <span>2</span>
          <p>填写新密码</p>
        </div>
      </div>
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <template v-if="index == 0">
          <el-form-item prop="number" label="手机号码">
            <el-input
              type="text"
              v-model="ruleForm.number"
              placeholder="请输入手机号码"
              maxlength="11"
              :validate-event="false"
            ></el-input>
          </el-form-item>
          <el-form-item prop="authCode" label="手机验证码">
            <el-input
              type="text"
              v-model="ruleForm.authCode"
              placeholder="请输入手机验证码"
              maxlength="6"
              :validate-event="false"
            >
              <template #append>
                <p
                  @click="CaptchaMethod"
                  :style="Captcha ? 'cursor: default;' : ''"
                >
                  {{ Captcha ? Captcha : "获取验证码" }}
                </p>
              </template>
            </el-input>
          </el-form-item>
        </template>
        <template v-if="index == 1">
          <el-form-item prop="pass" label="新密码">
            <el-input
              type="password"
              v-model="ruleForm.pass"
              placeholder="请输入新密码"
              :validate-event="false"
              maxlength="32"
            ></el-input>
          </el-form-item>
          <el-form-item prop="checkPass" label="确认密码">
            <el-input
              type="password"
              v-model="ruleForm.checkPass"
              placeholder="请再次输入密码"
              :validate-event="false"
              maxlength="32"
            ></el-input>
          </el-form-item>
        </template>
        <el-form-item>
          <el-button type="primary" @click="submitForm('ruleForm')"
            >下一步</el-button
          >
        </el-form-item>
      </el-form>
    </div>
    <div class="tips" v-else>
      <img src="@/assets/images/prosperity.png" alt="" />
      <p>您的密码已修改成功！</p>
      <el-button type="primary" @click="$router.push('/login')"
        >立即登录</el-button
      >
    </div>
  </div>
</template>

<script>
export default {
  name: "forget-password",

  data() {
    var validateNumber = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("手机号码不能为空"));
      } else if (
        !new RegExp(
          /^(?:(?:\+|00)86)?1(?:(?:3[\d])|(?:4[5-79])|(?:5[0-35-9])|(?:6[5-7])|(?:7[0-8])|(?:8[\d])|(?:9[189]))\d{8}$/
        ).test(value)
      ) {
        return callback(new Error("手机号码格式错误"));
      } else {
        callback();
      }
    };
    var validateauthCode = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("手机验证码不能为空"));
      } else {
        if (value === "test") {
          callback();
        } else {
          this.$API.personalUser
            .verificationCAPTCHA({
              mobile: this.ruleForm.number,
              captcha: this.ruleForm.authCode,
            })
            .then((res) => {
              if (!res.data.data.result) {
                return callback(new Error("验证码错误"));
              } else {
                callback();
              }
            });
        }
      }
    };
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else if (value.length < 6) {
        callback(new Error("密码最少为6位"));
      } else {
        if (this.ruleForm.checkPass !== "") {
          this.$refs.ruleForm.validateField("checkPass");
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.ruleForm.pass) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      ruleForm: {
        number: "",
        authCode: "",
        pass: "",
        checkPass: "",
      },
      rules: {
        number: [{ validator: validateNumber, trigger: "blur" }],
        authCode: [{ validator: validateauthCode, trigger: "blur" }],
        pass: [{ validator: validatePass, trigger: "blur" }],
        checkPass: [{ validator: validatePass2, trigger: "blur" }],
      },
      Captcha: false, //验证码倒计时
      index: 0,
    };
  },

  mounted() {},

  methods: {
    submitForm() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          if (this.index == 1) {
            this.updatePasswd();
          } else {
            this.index++;
          }
        } else {
          return false;
        }
      });
    },
    CaptchaMethod() {
      this.$refs.ruleForm.validateField(["number"], async (valid) => {
        if (!valid && !this.Captcha) {
          await this.$API.personalUser
            .sendCAPTCHA({
              mobile: this.ruleForm.number,
            })
            .then((res) => {
              this.$message({
                message: "发送成功",
                type: "success",
                offset: 420,
              });
              let t = 59;
              this.Captcha = `${t}s后重新获取`;
              this.t = setInterval(() => {
                t--;
                this.Captcha = `${t}s后重新获取`;
                if (t < 1) {
                  clearInterval(this.t);
                  this.Captcha = false;
                }
              }, 1000);
            })
            .catch(() => {
              this.$message({
                message: "发送失败",
                type: "error",
                offset: 420,
              });
            });
        }
      });
    },
    async updatePasswd() {
      await this.$API.personalUser
        .updatePasswdByUserName({
          object: {
            captcha: this.ruleForm.authCode,
            // captcha: "123456",
            password: this.ruleForm.checkPass,
            username: this.ruleForm.number,
          },
        })
        .then((res) => {
          if (res.data.code === "SUCCESS") {
            this.$message({
              message: "更改成功",
              type: "success",
              offset: 420,
            });
            this.$router.push('/Login')
          } else {
            this.$message.error(res.data.msg);
          }
        })
        .catch(() => {
          this.$message({
            message: "更改失败",
            type: "error",
            offset: 420,
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
#forget_password {
  // margin-top: 12px;
  background-color: #fff;
  padding: 120px 0;
  position: relative;
  .shadow {
    position: absolute;
    height: 10px;
    top: -9px;
    left: 0;
    right: 0;
    z-index: 0;
    box-shadow: 0px 2px 12px 1px rgba(0, 0, 0, 0.08);
  }

  .content {
    width: 400px;
    margin: 0 auto;

    .steps {
      display: flex;
      margin-bottom: 40px;
      div {
        flex: 1;
        text-align: center;
        position: relative;
        span:nth-child(1) {
          display: block;
          width: 200px;
          height: 5px;
          background: #cccccc;
        }
        span:nth-child(2) {
          display: block;
          width: 24px;
          height: 24px;
          border-radius: 50%;
          background: #cccccc;
          font-size: 12px;
          color: #ffffff;
          line-height: 24px;
          position: absolute;
          top: -9.5px;
          left: 50%;
          margin-left: -12px;
        }
        p {
          margin-top: 17px;
          height: 16px;
          font-size: 12px;
          color: #cccccc;
        }
      }
      .state {
        span {
          background: #33bb44 !important;
        }
        p {
          color: #33bb44;
        }
      }
    }
    ::v-deep .el-form {
      .el-form-item {
        margin-bottom: 34px;
        position: relative;
        .el-form-item__label {
          position: absolute;
          z-index: 100;
          top: 1px;
          left: 1px;
          line-height: 42px;
          text-align: start;
          padding-left: 15px;
          color: #333333;
        }
        .el-form-item__content {
          margin-left: 0 !important;
          .el-input {
            input {
              height: 44px;
              border-radius: 0;
              padding-left: 100px;
            }
            input::-webkit-outer-spin-button,
            input::-webkit-inner-spin-button {
              -webkit-appearance: none;
            }

            input[type="number"] {
              -moz-appearance: textfield;
            }
            .el-input-group__append {
              font-size: 12px;
              color: #333333;
              line-height: 18px;
              border-radius: 0;
              padding: 0;
              p {
                width: 102px;
                height: 42px;
                line-height: 42px;
                text-align: center;
                cursor: pointer;
              }
            }
          }
          .el-button {
            width: 100%;
            border-radius: 0;
            background: linear-gradient(132deg, #ff7300 0%, #fb5d20 100%);
          }
        }
      }
      .el-form-item:last-child {
        margin-bottom: 12px;
      }
    }
  }
  .tips {
    padding: 60px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    p {
      margin: 10px 0 30px 0;
      text-indent: 10px;
      color: #333;
      font-size: 18px;
    }
    .el-button {
      border-radius: 0;
      padding: 12px 171px;
    }
  }
}
</style>